<template>
  <div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import { toReturnUrl } from '@/api/car.js';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {};
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.$route.query);
    toReturnUrl(this.$route.query).then(res => {
      console.log(res.data.data.code);
      if (res.data.data.code == '1') {
        this.$router.push({
          path: '/paySuccess',
          query: {
            orderId: res.data.data.orderId
          }
        });
      }else {
        this.$message(res.data.data.msg)
        this.$router.push('/car')
      }
    });
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style>
/* //@import url(); 引入公共css类 */
</style>